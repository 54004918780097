import { NgModule,LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { registerLocaleData } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCustomErrorInterceptorService } from './services/error/http--custom-error-interceptor.service';

import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

import { IonicSelectableModule } from 'ionic-selectable';
import { DndDirective } from './direcitves/dnd.directive';



@NgModule({
    declarations: [AppComponent, DndDirective],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, IonicSelectableModule],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: HttpCustomErrorInterceptorService, multi: true },
        { provide: LOCALE_ID, useValue: 'fr-FR' }],
    bootstrap: [AppComponent]
})
export class AppModule {}
