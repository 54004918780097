// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true, //permet de faire des test a distance si false a combiner avec APP_TEST_IHM = true
  //api_url_racine : "https://tcmitelis2024.agencejill.com", //pour test a distance
  api_url_racine : "", //prod
  api_url : "/ihm",
  //api_url_oauth : "/oauth",
  strengthpwd:'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}',
  appPages : [
    { title: 'Recherche par bénéficiaire', url: '/accueil', icon: 'search',rule:'Recherche bénéficiaire' },
    { title: 'Recherche par entreprise', url: '/entreprise', icon: 'business' ,rule:'Recherche par entreprise'},
    { title: 'Export', url: '/export', icon: 'download',rule:'Export' },
    { title: 'Suivi', url: '/suivi', icon: 'glasses' ,rule:'Suivi'},
    { title: 'Suivi des tests', url: '/suivitest', icon: 'document-text' ,rule:'Test import'},
    { title: 'Test de fichier', url: '/testfichier', icon: 'document' ,rule:'Test import'},
    { title: 'Mot de passe', url: '/chgmdp', icon: 'build',rule:'' },
    { title: 'Webservice', url: '/sandbox', icon: 'globe', rule:'Webservice', target:'_blank' },
    { title: 'Déconnexion', url: '/deconnexion', icon: 'log-out',rule:'' },
  ],
  file_max_size:300 // en Mo
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
